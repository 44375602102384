<template>
  <div
    class="page initiatives"
    :loaded="Boolean(initiatives) && Boolean(page)"
    :dialog="Boolean(selectedInitiative)"
  >

    <section class="top-section" v-if="page">
      <div class="wrapper">
        <div class="container">
          <div class="content">
            <div class="title">Regional Coworking Hubs</div>
            <h3>{{page.title}}</h3>
            <h5>{{page.subtitle}}</h5>
            <p>{{page.description}}</p>
          </div>
          <div class="section-image">
            <img :src="page.image" alt="">
          </div>
        </div>
      </div>
    </section>

    <section class="sectors">
      <div class="wrapper">
        <div class="sector">
          <div class="container">
            <div
              class="initiative"
              v-for="initiative in initiatives"
              :key="initiative.id"
            >
              <div class="initiative-cover">
                <img v-if="initiative.background" :alt="initiative.name" :src="initiative.background">
              </div>
              <div class="initiative-image-container">
                <img :src="initiative.image" :alt="initiative.name" class="initiative-image">
              </div>
              <div class="initiative-content">
                <h5>{{initiative.name}}</h5>
                <div class="initiative-description" v-html="getExcerpt(initiative.description)"></div>
                <div class="buttons">
                  <a class="btn medium ghost" :href="formatLink(initiative.link)" target="_blank">
                    Visit website
                    <Icon type="fas" icon="arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";
import Dropdown from "./Dropdown.vue";

export default {
  name: "Initiatives",
  components: {
    Icon,
    Dropdown,
  },
  data() {
    return {
      initiatives: [],
      page: null,
      selectedInitiative: null,
      selectedSector: null,
      selectedTime: null,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
  },
  methods: {
    getExcerpt(text) {
      if (!text) {
        return '';
      }
      return text.replace(/\n/g, '<br>');
    },
    formatLink(link) {
      return /^https?:\/\//.test(link) ? link : `http://${link}`;
    },
    getItems() {
      if (this.initiativesRef) this.initiativesRef();
      if (this.pageRef) this.pageRef();
      this.pageRef = this.db()
        .doc(`${this.testMode ? "test_" : ""}pages/hubs`)
        .onSnapshot((settings) => {
          this.page = settings.data();
        });
      this.initiativesRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}hubs`)
        .orderBy("order")
        .onSnapshot((initiatives) => {
          this.initiatives = initiatives.docs.map(doc => doc.data());
        });
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
    if (this.initiativesRef) this.initiativesRef();
  },
};
</script>

<style scoped>
.top-section {
  margin-bottom: 64px;
}

.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(28, 172, 214, 0.08);
  border-radius: 16px;
  min-height: 568px;
  padding: 48px 0;
  margin-right: 144px;
}

.top-section .container .content {
  position: relative;
  width: calc(50% + 72px);
  padding: 0 42px 0 96px;
  min-width: 420px;
  z-index: 2;
}

.top-section .container .content h3 {
  font-size: 72px;
}

.top-section .container .content h5 {
  margin: 16px 0 32px;
  line-height: 1.2;
  color: #656662;
}

.top-section .container .content p {
  font-size: 18px;
}

.top-section .content .buttons {
  margin-top: 64px;
}

.top-section .section-image {
  position: absolute;
  top: 24px;
  right: -144px;
  width: calc(50% + 72px);
  bottom: 24px;
  border-radius: 16px;
  background-color: #fafafa;
  overflow: hidden;
}

.top-section .content .title {
  display: flex;
  align-items: center;
  color: #1dacd6;
  font-size: 20px;
  margin-bottom: 24px;
}

.top-section .content .title:before {
  content: '';
  display: block;
  width: 112px;
  height: 21px;
  background: url(../assets/images/wb_o_4dots.svg) no-repeat center/contain;
  margin-right: 24px;
}

.top-section .section-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.sector {
  margin-bottom: 32px;
}

.sector h4 {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 40px;
}

.selectors {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;
  font-weight: 600;
}

.selectors .selector {
  display: flex;
  align-items: center;
  width: 200px;
  height: 96px;
  padding: 0 32px;
}

.sector .container {
  display: flex;
  flex-wrap: wrap;
}

.sector .initiative {
  display: flex;
  flex-direction: column;
  width: calc(33.3333333% - 16px);
  margin: 0 8px 64px;
  border-radius: 16px;
  border: 1px solid rgba(101, 102, 98, 0.08);
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  transition: all .2s ease-out;
}

#app[no-touch] .sector .initiative:hover {
  box-shadow: 0 24px 32px rgba(52, 52, 52, 0.12);
  transform: translateY(-8px);
}

.sector .initiative h5 {
  font-size: 22px;
  margin-bottom: 16px;
}

.sector .initiative .initiative-content {
  padding: 0 32px 32px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.sector .initiative .initiative-cover {
  width: 100%;
  height: 285px;
  background: #f2f2f2;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.sector .initiative .initiative-cover img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.sector .initiative .initiative-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 196px;
  height: 196px;
  border-radius: 50%;
  margin: -98px auto 56px;
  background: #fff;
  padding: 16px;
}

.sector .initiative .initiative-image-container:after {
  content: '';
  position: absolute;
  top: -11%;
  left: 8%;
  width: 108%;
  height: 122%;
  background: url(../assets/images/wb_o_crest.svg) no-repeat center right/contain;
}

.sector .initiative .initiative-image-container img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.sector .initiative .initiative-description {
  font-size: 18px;
  flex: 1;
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 64px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease-in;
}

.overlay[visible] {
  opacity: 1;
  pointer-events: all;
}

.overlay .modal {
  background: #fff;
  border-radius: 16px;
  border: 1px solid rgba(101, 102, 98, 0.08);
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  width: 100%;
  max-width: 1024px;
  padding: 64px 96px;
}

.overlay .modal .modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.overlay .modal .modal-header img {
  height: 100px;
}

.overlay .modal .modal-header a {
  color: #1DACD6;
}

.overlay .modal .company-info {
  margin: 24px 0;
}

.overlay .modal .partner-logos {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin: 0 24px;
}

.overlay .modal .partner-logo {
  margin-left: 16px;
}

.overlay .modal .partner-logos img {
  display: block;
  height: 64px;
}

@media screen and (max-width: 1280px) {
  .sector .initiative {
    width: calc(50% - 16px);
    margin-bottom: 48px;
  }
  .top-section .container .content {
    padding: 48px 64px;
  }
  .top-section .container .content h3 {
    font-size: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .page-header .wrapper {
    padding: 0 64px;
  }
  .page-header h3, .page-header h5, .page-header p {
    max-width: 480px;
  }
  .overlay {
    align-items: flex-start;
    padding: 24px;
  }
  .overlay .modal {
    padding: 24px;
  }
  .top-section .container {
    flex-direction: column-reverse;
    margin-right: 0;
  }
  .top-section .section-image {
    position: relative;
    height: 360px;
    right: 0;
    width: calc(100% - 96px);
    top: 0;
    margin: 0 48px;
  }
  .top-section .container .content {
    width: auto;
  }
  .top-section .container .content h3 {
    font-size: 52px;
  }
}

@media screen and (max-width: 880px) {
  .page-header .wrapper {
    background: none;
  }
  .page-header h3, .page-header h5, .page-header p {
    max-width: none;
  }
}

@media screen and (max-width: 800px) {
  .sector .container {
    flex-direction: column;
    align-items: center;
  }
  .sector .initiative {
    width: 100%;
    max-width: 440px;
    margin: 0 0 24px;
  }
  .top-section .container .content {
    padding: 24px 24px 0;
  }
  .top-section .section-image {
    width: calc(100% - 48px);
    margin: 0 24px;
  }
  .top-section .container {
    padding-top: 24px;
  }
}

@media screen and (max-width: 520px) {
  .page-header .wrapper {
    padding: 0 32px;
  }
  .selectors .selector {
    width: auto;
    padding: 0 16px;
  }
  .sector .initiative .initiative-cover {
    height: 240px;
  }
  .sector .initiative .initiative-image-container {
    width: 152px;
    height: 152px;
    margin-top: -76px;
  }
  .sector .initiative .initiative-description {
    font-size: 16px;
  }
}
</style>
